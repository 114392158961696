#layout-container {
  outline: 1px solid grey;
  min-height: 80dvh;
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-template-rows: repeat(5, 120px);
  padding: 1rem;
  margin-block: 1rem;
  margin-inline: auto;
  border-radius: 10px;
}

#telefonResimleri {
  grid-row-start: 1;
  grid-column-start: 1;
  width: 100%;
}

#telefonMarkaları {
  grid-row-start: 2;
  grid-column-start: 1;
}

#telefonModelleri {
  grid-row-start: 2;
  grid-column-start: 2;
}

#telefonRenkleri {
  grid-row-start: 3;
  grid-column-start: 1;
}

#telefonDepolama {
  grid-row-start: 3;
  grid-column-start: 2;
}

#telefonFiyat {
  grid-row-start: 4;
  grid-column-start: 1;
}

#telefonFiyatInput {
  font-size: 32px;
  font-weight: 800;
  width: 50%;
}

#telefonAciklama {
  grid-row-start: 4;
  grid-column-start: 2;
}

#telefonAciklamaArea {
  width: 35dvw;
}

#telefonBaslik {
  grid-row-start: 1;
  grid-column-start: 2;
}

#telefonBaslikArea {
  width: 35dvw;
}

#upload-progress {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
}

.progress {
  margin-top: 3px;
  width: 55%;
}
