#home-container {
  width: 100dvw;
  max-width: 100%;
  display: grid;
  grid-template-columns: 20% 80%;
  grid-row: 3;
  gap: 0.5rem;
}

#pagination-container {
  width: min-content;
  margin-inline: auto;
  position: absolute;
  bottom: 0;
}

.buttons-container {
  grid-row-start: 1;
  grid-column-start: 1;
}

#add-phone {
  grid-row-start: 1;
  width: 100%;
}

#phones-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100dvh;
  overflow-y: auto;
  max-width: max-content;
  padding: 1rem;
  border-radius: 10px;
  grid-row-start: 2;
  gap: 0.5rem;
}

.data-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-height: 80dvh;
  width: min-content;
}

#filter-container {
  min-height: 80dvh;
  min-width: 300px;
  padding: 1rem;
  border-radius: 10px;
  grid-row-start: 2;
}

.filters-btns-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  margin-top: 5%;
  gap: 0.3rem;
}

.filters-btns-container .btn {
  width: 100%;
}

.link {
  text-decoration: none;
  color: green;
}

#add-phone:hover {
  #link {
    color: white;
  }
}

.accordion-button:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0.125);
  background-color: transparent;
}

.accordion-button:not(.collapsed) {
  color: black;
  background-color: white;
}

@media screen and (max-width: 1500px) {
  #home-container {
    grid-template-columns: 300px 1000px;
  }
}
