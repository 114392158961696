#update-layout-container {
  outline: 1px solid grey;
  min-height: 90dvh;
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-template-rows: repeat(4, 120px);
  padding: 1rem;
  margin-block: 1rem;
  margin-inline: auto;
  border-radius: 10px;
}

#telefonBaslik2 {
  grid-row-start: 1;
  grid-column-start: 1;
}

#telefonBaslikArea2 {
  width: 35dvw;
}

#telefonMarkalarıUpdate {
  grid-row-start: 2;
  grid-column-start: 1;
}

#telefonModelleriUpdate {
  grid-row-start: 2;
  grid-column-start: 2;
}

#telefonRenkleriUpdate {
  grid-row-start: 3;
  grid-column-start: 1;
}

#telefonDepolamaUpdate {
  grid-row-start: 3;
  grid-column-start: 2;
}

#telefonFiyatUpdate {
  grid-row-start: 4;
  grid-column-start: 1;
}

#telefonAciklamaUpdate {
  grid-row-start: 4;
  grid-column-start: 2;
}

#telefonFiyatInputUpdate {
  font-size: 32px;
  font-weight: 800;
  width: 50%;
}

#telefonAciklamaAreaUpdate {
  width: 35dvw;
}
