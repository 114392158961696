#detail-container-sm {
  display: grid;
  justify-content: center;
  grid-template-columns: 65% 35%;
  grid-template-rows: 4;
  outline: 1px solid grey;
  border-radius: 15px;
  min-width: 70dvw;
  min-height: 80dvh;
  margin-inline: auto;
  margin-block: 2rem;
}

.carousel-container {
  min-width: 100%;
  min-height: 300px;
  max-height: 500px;
  border-radius: 15px;
  grid-row-start: 1;
  grid-column-start: 1;
  outline: 1px solid grey;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-inner {
  width: 100%;
  height: 600px;
}

.carousel-item > img {
  width: 100%;
  height: 600px;
  object-fit: contain;
}

.description-container {
  border-radius: 15px;
  grid-row-start: 2;
  grid-column-start: 1;
  min-height: 300px;
}

.phone-detail-container {
  border-radius: 15px;
  grid-row-start: 1;
  grid-column-start: 2;
  min-height: 100px;
}

.phone-settings-container {
  border-radius: 15px;
  grid-row-start: 2;
  grid-column-start: 2;
  gap: 1rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.btn {
  padding-block: 1rem;
  border-radius: 15px;
}

#indicatorContainer {
  display: flex;
  position: relative;
  background-color: white;
  padding: 0.2rem;
  justify-content: space-around;
}

#indicatorImg {
  width: 100%;
  height: 70px;
  object-fit: contain;
}

#indicatorBtn {
  height: 70px;
  width: 80px;
}
#updatePhoneLink .btn {
  width: 100%;
}
@media screen and (max-width: 1200px) {
  #detail-container-sm {
    grid-template-columns: 100%;
  }
  .phone-detail-container {
    grid-column-start: 1;
    grid-row-start: 3;
  }

  .phone-settings-container {
    grid-column-start: 1;
    grid-row-start: 4;
  }

  .description-container {
    grid-row-start: 2;
    min-height: 50%;
  }
}
